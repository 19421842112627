// If you want to override variables do it here
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui/scss/coreui.scss';

// Some temp fixes
@import 'fixes';

// If you want to add something do it here
@import 'custom';
